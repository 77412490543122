import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import myImg from "../../Assets/avatar.svg";
import myImg from "../../Assets/snake.png";
import Tilt from "react-parallax-tilt";
// import {
//   AiFillGithub,
//   // AiOutlineTwitter,
//   // AiFillInstagram,
// } from "react-icons/ai";
// import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" >
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME TELL YOU A <span className="purple"> LOVE </span> STORY
            </h1>
            <p className="home-about-body">
               
              
              <br />I speak the language of serpents 🐍, working with them day by day. Among them, one has captured my heart—
              <i>
                 <b className="purple"> Python </b> my true love, in every way.
              </i>
              <br />
              <br />
              Each line of code weaves a symphony of logic and creativity, and with every function, my affection for its simplicity and power grows. Python, you've captured my heart in the most profound and passionate way.
              {/* <i> */}
                {/* <b className="purple">Web Technologies and Products </b> and
                also in areas related to{" "}
                <b className="purple">
                  GameDev.
                </b> */}
                
              {/* </i> */}
              <br />
              <br />
              and I have at least learnt something, I think… 🤷‍♂️
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>

        </Row>
        <div align="center">
        <br/><br/><br/>
          <h1 className="purple">🐍 My Love Eating My Contributions 🐍</h1>
          <br/>
          <img style={{width:'100%'}} alt="snake eating my contributions" src="https://raw.githubusercontent.com/Balaji2810/Balaji2810/output/github-contribution-grid-snake-dark.svg"/>
          
          <br/><br/><br/>
        </div>
        {/* <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Balaji2810"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              
            </ul>
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
}
export default Home2;
