import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I am a <span className="purple">Backend </span> Developer with a passion for building scalable and reliable systems based 
            out of <span className="purple"> Chennai, India.</span>
            <br />
            <br />
            As a backend developer, I am passionate about building innovative web technologies and products. 
                My interests include developing cutting-edge web applications, autoscaling, deployments, and 
                optimizing CI/CD pipelines to ensure efficient and scalable solutions.
            <br/>
            <br />
            I am currently employed as a <span className="purple"> Founding Engineer</span> (<span className="purple">Tech Lead</span>) at <span className="purple">Nexera</span>.
            <br />
            I have completed Bachelors in <span className="purple"> Computer Science</span> Engineering            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading Manhua
            </li>
            <li className="about-activity">
              <ImPointRight /> Watching Anime
            </li>
          </ul>

          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
