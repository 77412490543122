import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
// import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";

// import Home2 from "./components/Home/Home2";

import {
  BrowserRouter as Router,
  // Route,
  // Routes,
  // Navigate
} from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";

import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

import {
  AiFillGithub, AiFillLinkedin
} from "react-icons/ai";
import { FaEnvelope,FaInstagram } from "react-icons/fa";

function App() {
  const [load, upadateLoad] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />

        <Home />
        <About />
        <Projects />
        <Resume />

        <Container>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>FIND ME ON</h1>
              <p>
                Feel free to <span className="purple">connect </span>with me
              </p>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href="https://github.com/Balaji2810"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <AiFillGithub />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.instagram.com/balaji.official/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="mailto:bstudies28@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaEnvelope />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.linkedin.com/in/balaji-j-6725b2311/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <AiFillLinkedin />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </Container>

        {/* <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes> */}
        
        {/* <Footer /> */}
      </div>
    </Router>
  );
  
}

export default App;
